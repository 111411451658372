.confirm-cancel-modal {
  --width: 540px;
  --border-radius: 4px;
  color: #454545;
  --height: fit-content;
  font-family: "Montserrat",sans-serif;
}

.confirm-cancel-modal-border #ion-react-wrapper{
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 6px solid #6A6EFF;
}

.confirm-cancel-modal #ion-react-wrapper {
  border-top: 6px solid #6A6EFF;
}



.confirm-cancel-modal-content #background-content {
  background-color: white !important;
}

.confirm-cancel-modal-content p {
  margin: 0;
}

.confirm-cancel-modal-actions {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin-top: 8px;
  height: 60px;
  padding-bottom: 16px;
  padding-right: 8px;
}

.confirm-cancel-modal-actions ion-button {
  margin: 0 8px;
  width: 160px;
}

.confirm-cancel-modal-content-wrapper {
  padding: 24px 16px 16px 16px;
  background-color: white;
}

.confirm-cancel-cancel-button {
  --border-width: 1px;
  --background: white;
  color: #6A6EFF;
  --box-shadow: none !important;
  border: 1px solid #6A6EFF;
}

.confirm-cancel-button {
  text-transform: capitalize;
  --box-shadow: none !important;
  height: 40px;
}

.confirm-cancel-modal-icon-container {
  padding-top: 10px;
}

.confirm-cancel-modal-icon {
  color: #6A6EFF;
}

.confirm-cancel-modal-header .big-header {
  font-size: 30px;
}

.confirm-cancel-modal-header .small-header {
  font-size: 18px;
}

.confirm-cancel-modal-header {
  padding-left: 16px;
}

.confirm-cancel-modal-footer {
  color: #6A6EFF;
  font-weight: 650;
}

.confirm-cancel-modal-subheader {
  font-size: 16px;
  font-weight: 400;
}

