.help-popover-content {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #454545;
  border-radius: 4px;
  box-shadow: none;
  overflow: hidden;
}

.help-popover-head {
  color: #454545;
  margin-bottom: 16px;
  width: 100%;
  padding: 10px 10px 0 10px;
}

.help-popover-logo-container {
  display: inline-block;
  width: 25%;
  margin-right: 8px;
}

.help-popover-logo-img {
  max-width: 85%;
  max-height: 85%;
}

.help-popover-contact-us {
  font-weight: 500;
  font-size: 1.4em;
}

.help-popover-contact-img {
  max-width: 8%;
  max-height: 8%;
}

.help-popover-link-container {
  background-color: white;
  border-radius: 4px;
}

.help-popover-link {
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  padding: .5rem 1rem;
  position: relative;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.help-popover-link:hover {
  background-color: #f0f0f0;
}

.help-popover-version-number {
  font-weight: 400;
  text-decoration: none;
  padding: .5rem 1rem;
  position: relative;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.help-popover-version-number .help-popover-line{
  color: #8c8c8c !important;
}

.help-popover-line {
  font-weight: 400;
  list-style: none;
  cursor: pointer;
  line-height: 1.5;
  font-size: 0.9em;
  box-sizing: inherit;
  color: #454545;
  margin-left: 10px;
  display: block;
}

.help-popover-line-item {
  padding-top: 4%;
}

.help-popover-contact-info {
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
  color: #454545;
  display: inline-block;
  width: 70%;
  height: 100%;
}

.help-popover-anchor {
  cursor: pointer;
}

.help-popover-item-container {
  position: absolute;
  bottom: 50px;
  left: 0;
  color: lightgrey;
  width: 100%;
  --ion-background-color: transparent;
}

.help-popover-item-container:hover {
  --ion-background-color: #f0f0f0;
}

.help-icon {
  padding: 5px;
  display: inline-block;
  margin: 0 10px 0 0;
  background-color: var(--cas-menu-color-inactive-color);
  border-radius: 50%;
  color: var(--cas-menu-color-inactive-icon-color);
  min-width: 25px;
}

.help-popover::part(content) {
  top: unset;
  left: 320px;
  bottom: 60px;
}
