.panel-button-enabled {
  width: 90%;
  height: 48px;
  margin: 12px;
  text-transform: capitalize;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-size: 1.3em;
  vertical-align: center;
  color: #6A6EFF;
  cursor: pointer;
  font-weight: 400;
  box-shadow: 1px 0 4px #e6e6e6;
}

.panel-button-disabled {
  width: 90%;
  height: 48px;
  margin: 12px;
  text-transform: capitalize;
  border: 1px solid transparent;
  font-size: 1.3em;
  vertical-align: center;
  cursor: pointer;
  font-weight: 400;
}

.panel-button-content {
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 12px;
  padding-top: 12px;
}

.account-settings-button-panel {
  padding: 8px 8px 8px 8px;
  margin-top: 20px;
  box-shadow: none;
  border: 1px solid #dbdbdb;
}

.account-settings-modal-container {
  height: 94px;
  width: 100%;
  font-family: "Montserrat",sans-serif;
}

.account-settings-container-footer {
  padding-top: 10px;
  padding-left: 20px;
}

.account-settings-footer-button {
  text-transform: capitalize;
  width: 20%;
  margin-left: 12px;
}

.account-settings-title {
  font-family: "Montserrat",sans-serif;
  margin: 4px 4px 20px 4px;
  font-weight: bold;
  display: inline-block;
  font-size: 1.6em;
  width: 80%;
  white-space: nowrap;
  padding-left: 6px;
}

.account-settings-title-icon {
  font-weight: bold;
  height: 30px;
  width: 30px;
  color: #454545;
  vertical-align: top;
}

.account-settings-email-icon {
  font-weight: bold;
  height: 27px;
  width: 27px;
  color: #454545;
  vertical-align: top;
}

.account-settings-email-verification-input-col {
  padding-top: 9px !important;
}

.account-settings-email-verification-input {
  box-shadow: 0 1px #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  height: 84%;
  background-color: white;
  font-weight: 600;
}

.account-settings-email-button-verify {
  width: 100%;
}

.account-settings-email-button-refresh-token {
  width: 100%;
  background: white;
}

.account-settings-email-button-cancel {
  width: 100%;
  background: white;
}

.account-settings-email-verification-card {
  color: #1e2023;
  box-shadow: 0 1px 0 0 #e6e6e6;
  border: 1px solid #e6e6e6;
}

.account-settings-title-email-verification {
  font-family: "Montserrat",sans-serif;
  margin: 4px 4px 20px 4px;
  font-weight: bold;
  display: inline-block;
  font-size: 1.2em;
  width: 80%;
}

.account-settings-email-verification-text {
  padding-left: 20px;
  margin-top: 8px;
}

.account-settings-email-verification-title-container {
  padding: 10px;
}

.account-settings-alt-button {
  --border-width: 1px;
}

.account-settings-left-col {
  /*margin-left: 40px;*/
}

.account-settings-panel {
  /*margin-left: -60px;*/
}

.account-settings-single-line-item {
  width: 92% !important;
}

.account-settings-two-factor {
  width: 45% !important;
}

.account-settings-spacer-left-col {
  width: 20px !important;
  max-width: 20px !important;
}

@media (max-width: 1350px) {
  .account-settings-button-panel span {
    font-size: 12px;
  }

  .account-settings-button-panel ion-card-title {
    font-size: 14px;
  }

  .account-settings-button-panel span ion-icon {
    height: 16px;
    width: 16px;
  }

  .panel-button-content ion-icon {
    height: 12px;
    width: 12px;
  }
}
