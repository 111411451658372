.account-settings-item-container {
  position: absolute;
  bottom: 100px;
  left: 0;
  --ion-background-color: transparent;
  width: 100%;
  height: 50px;
}

.account-settings-item-container:after {
  width: 0;
}

.account-settings-label {
  color: lightgrey !important;
}

.account-settings-anchor {
  cursor: pointer;
}

.account-settings-icon {
  padding: 5px;
  display: inline-block;
  margin: 0 10px 0 0;
  background-color: var(--cas-menu-color-inactive-color);
  border-radius: 50%;
  color: var(--cas-menu-color-inactive-icon-color);
  min-width: 25px;
}

.account-settings-item-container .item-inner {
  border-style: none !important;
  border-color: transparent;
}
