.seat-debug {
  max-height: 100%;
  height: 100%
}

.seat-debug-header {
  height: 64px;
  background-color: var(--ion-color-primary);
  color: var(--body-background-color);
  position: relative;
}

.seat-debug-header ion-title {
  position: absolute;
  bottom: 15px;
  font-size: 28px;
  font-family: var( --ion-font-family);
}

.seat-options ion-row {
  display: flex;
  text-align: center;
  width: 100%;
}

.seat-options ion-button{
  width: 15%;
  min-width:140px;
  margin: 4px 10px 4px 10px;
  text-transform: capitalize;
}

.seat-data {
  display: table;
  border-collapse: collapse;
  float: left;
  margin: 10px 10px;
  --background: var(--body-background-color)
}

.seat-data ion-col {
  display: table-cell;
  border: 1px solid var(--body-background-color);
  margin: -1px 0 0 -1px;
  font-family: var( --ion-font-family);
}

.seat-log {
  margin: 5px;
  min-height: 100px;
}

.seat-log ion-title {
  padding: 0;
  margin: 0;
}

.seat-log ion-button {
  width: 80px;
  float:right;
  text-transform: capitalize;
}

.seat-log .log-data{
  background: var(--body-background-color);
  border-radius: 5px;
  margin: 10px auto;
  height: 300px;
  display: block;
  padding: 16px;
  overflow: auto;
  align-items: flex-start;
}

.log {
  --background: var(--body-background-color);
  font-size: 14px;
  font-family: monospace;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}

.log-debug {
  color: var(--log-debug);
}

.log-error {
  color: var(--log-error);
}

.log-warn {
  color: var(--log-warn);
}

.log-info {
  color: var(--log-info);
}

.log-panic {
  color: var(--log-panic);
}

.log-success {
  color: var(--log-success);
}

.seat-debug-modal {
  --height: 85%;
  --width: 85%;
  --max-height: 888px;
  --max-width: 1200px;
  --background: var(--body-background-color);
}

.seat-debug-modal-close-btn {
  width: 150px;
  text-transform: capitalize;
  float: right;
}


ion-modal.casana-modal p, ion-modal.casana-modal h1 {
  font-family: "Montserrat", sans-serif;
}

ion-modal.casana-modal ion-button {
  margin: 20px auto;
}


ion-modal.casana-modal ion-button button {
  background-color: var(--ion-color-primary);
  border-radius: 5px;
}

.seat-debug-modal-close-icon {
  float: right;
  margin: 20px;
  font-size: 24px;
  cursor: pointer;
}
