.authentication-form-text {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
}

.authentication-form {
    margin: auto;
    padding: 0 8%;
}

.authentication-form ion-item {
    --background: var(--cas-input-background);
    margin-top: 1rem;
    color: var(--cas-input-color);
}

.authentication-form ion-button {
    margin-top: 1rem;
    width: 100%;
    text-transform: capitalize;
}

.authentication-form a{
    text-decoration: none;
}

.auth-form-link {
  cursor: pointer;
}

.authentication-form .auth-form-link,
.authentication-form p,
.authentication-form ion-item ion-icon {
    color: var(--ion-color-medium);
}

.authentication-form ion-img.casana-logo {
    width: 75px;
    margin: 7vh auto;
}

.auth-form-eula-modal {
  --height: 80%;
  --width: 80%;
  --max-height: 828px;
  --max-width: 1200px;
  --background: var(--body-background-color);
  --border-radius: 4px;
  border-radius: 8px;
  font-family: "Montserrat",sans-serif;
}

.auth-form-eula-modal #ion-react-wrapper {
  border-top: 6px solid #6A6EFF;
}

.auth-form-eula-content {
  font-family: "Montserrat",sans-serif !important;
}

.auth-form-eula-modal #ion-react-wrapper {
  font-family: "Montserrat",sans-serif;
}

.auth-form-eula-header {
  height: 64px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  color: #454545;
  border-bottom: 1px solid lightgrey;
}

.auth-form-eula-footer {
  border-top: 1px solid lightgrey;
}

.auth-form-eula-icon {
  margin-left: 8px;
  font-size: 38px;
}

.auth-form-eula-modal-inner-container {
  margin-left: 32px !important;
  height: 100%;
  width: 95%;
}

.auth-form-eula-modal-inner {
  margin-left: 24px !important;
  font-size: smaller !important;
  font-family: "Montserrat",sans-serif !important;
  color: #454545 !important;
  scrollbar-base-color: #6A6EFF !important;
}

.auth-form-eula-modal-inner h1 {
  font-size: 22px !important;
  text-align: center !important;
}

.auth-form-eula-modal-inner h2 {
  font-size: 18px !important;
  text-align: center !important;
}

.auth-form-eula-modal-inner p,ul,ol {
  color: #454545 !important;
}

.auth-form-eula-modal-btn {
  width: 100px;
  float: right;
  margin: 8px;
  text-transform: capitalize;
}

.auth-form-eula-subtitle {
  font-weight: 450 !important;
  font-size: 30px !important;
  margin-left: 8px !important;
  vertical-align: top;
  color: black;
}
