ion-split-pane {
  --side-min-width: 280px;
  --side-max-width: 280px;
}

ion-menu > ion-content > ion-thumbnail > ion-img.casana-logo {
  display: block;
  margin: 10px auto 0 10px;
  width: 50px;
}

ion-menu ion-button {
  margin: 0;
  width: 100%;
}
ion-menu ion-button ion-icon {
  display: none;
}

@media (max-width:992px)  {
  ion-split-pane {
    --side-min-width: 70px;
    --side-max-width: 70px;
  }

  ion-menu > ion-content > ion-thumbnail > ion-img.casana-logo {
    width: 50px;
  }

  .casana-wordmark {
    display: none;
  }

  ion-menu ion-button ion-icon {
    display: block;
    margin-right: -10px;
  }

  ion-menu ion-button ion-text {
    display: none;
  }
}

@media (min-width:993px)  {
  ion-menu > ion-content > ion-thumbnail > ion-img.casana-logo {
    display: none;
  }

  .casana-wordmark {
    background-color: var(--ion-color-primary);
    text-align: left;
  }

  .casana-wordmark-img {
    height: 62px;
    width: 180px;
  }
}

ion-menu.menu-pane-visible {
  --background: #F8F8F8;
  border-right: 1px solid #dbdbdb;
}

ion-menu ion-content.md.content-ltr {
  --background: #F8F8F8;
}

.list-md {
  background: #F8F8F8;
}

ion-menu ion-content ion-list ion-item {
  --background: transparent;
}

ion-menu ion-content ion-list{
  --background: transparent;
}

ion-menu > ion-content > ion-thumbnail {
  margin-bottom: 40px;
  --size: auto;
}

/* ==========================================================================
Progress
========================================================================== */

/* DEFAULT BUTTON STATE */
.progress-btn{
  cursor: hover;
}

.progress-btn ion-label {
  display: block;
  color: var(--cas-menu-color-inactive-color) !important;
}

.progress-btn ion-icon {
  padding: 5px;
  display: inline-block;
  margin: 0 10px 0 0;
  background-color: var(--cas-menu-color-inactive-color);
  border-radius: 50%;
  color: var(--cas-menu-color-inactive-icon-color);
  min-width: 25px;
}


.progress-btn:after {
  content: "";
  margin-left: 33px;
  display: block;
  width: 1px;
  height: 5vh;
  background-color: var(--cas-menu-color-inactive-color);
  z-index: 1;
}

/* ACTIVE BUTTON STATE */
.progress-btn.active ion-label {
  color: var(--cas-menu-color-active-color) !important;
}

.progress-btn.active ion-icon {
  background-color: var(--cas-menu-color-active-color);
}

.progress-btn.active:after {
  background-color: var(--cas-menu-color-active-color);
}


/* VISITED BUTTON STATE */
.progress-btn.visited ion-label {
  color: var(--cas-menu-color-visited-color) !important;
}

.progress-btn.visited ion-icon {
  color: var(--cas-menu-color-visited-icon-color);
}

.progress-btn.visited:before {
  background-color: var(--cas-menu-color-visited-color);
}

.progress-btn.visited:after {
  background-color: var(--cas-menu-color-visited-color);
}

/* DISABLED BUTTON STATE */
.progress-btn.disabled ion-label {
  color: var(--cas-menu-color-disabled-color) !important;
}

.progress-btn.disabled ion-icon {
  color: var(--cas-menu-color-disabled-icon-color);
}

.progress-btn.disabled:before {
  background-color: var(--cas-menu-color-disabled-color);
}

.progress-btn.disabled:after {
  background-color: var(--cas-menu-color-disabled-color);
}

/* HOVER BUTTON STATE */
.progress-btn:hover ion-icon {

}

/* TRIM NAVIGATION LINES */
.progress-btn:first-child:before {
  display: none;
}

.progress-btn:last-child:after {
  display: none;
}
