.init-container {
  background: transparent;
}

.pair-accordion ion-icon, .pair-accordion-text{
  color: var(--ion-color-primary) !important;
}

.initialize-link {
  cursor: pointer;
  margin-left: 15px;
}

.initialize-timer, .initialize-screen {
  padding-top: 0;
  padding-bottom: 100px;
}

.timer-btn {
  width: 80px;
  text-transform: capitalize;
}

.timer-btn.reset {
  --background: var(--ion-color-danger);
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.retake-recording {
  --background: var(--cas-input-background);
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  color: var(--ion-color-primary);
}

.retake-recording, .upload-recording {
  width: 98%;
  max-width: 200px;
  text-transform: inherit;
  cursor: pointer;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.initialize-form-submit {
  width: 100%;
  text-transform: capitalize;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

#sit-timer-duration {
  margin: auto;
  text-align: center;
  border: 2px solid lightgrey;
  border-radius: 4px;
  width: 164px;
}

.sit-session-list {
  background-color: #FFFFFF;
}

.init-form ion-item {
  border-top: 1px solid #F0F0F0;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-radius: 5px;
}

ion-text.blockquote{
  padding: 0 .5rem;
  margin: 0 0 5rem;
  border-left: 4px solid var(--ion-color-step-550, #b7b7b7);
  display: block;
}

@media (min-width: 1600px) {
  .init-intro-record-method-card {
    width: 260px;
    height: 300px;
    padding: 40px 10px 10px 10px;
    font-size: small;
    margin: 40px 20px 20px 40px;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 8px;
    box-shadow: none;
    display: inline-block;
  }

  .init-intro-record-method-card:hover {
    outline: 4px solid lightgrey;
  }

  .init-intro-record-method-card.selected {
    outline: 4px solid var(--ion-color-primary);
  }

  .init-intro-record-method-card ion-card-title {
    font-size: xx-large;
    font-weight: 650;
    text-align: center;
    margin-bottom: 10px;
  }

  .init-intro-record-method-card ion-card-content {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 1599px) {
  .init-intro-record-method-card {
    width: 160px;
    height: 200px;
    padding: 24px 10px 10px 10px;
    margin: 20px 20px 20px 20px;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 8px;
    box-shadow: none;
    display: inline-block;
  }

  .init-intro-record-method-card:hover {
    outline: 2px solid lightgrey;
  }

  .init-intro-record-method-card.selected {
    outline: 2px solid var(--ion-color-primary);
  }

  .init-intro-record-method-card ion-card-title {
    font-size: large;
    font-weight: 650;
    text-align: center;
    margin-bottom: 6px;
  }

  .init-intro-record-method-card ion-card-content {
    font-size: 12px;
    text-align: center;
  }
}

.init-intro-method-container {
  text-align: center;
}

.init-small-text {
  font-size: 12px;
}

.init-error-message {
  margin-bottom: 8px;
}

.init-weight-field {
  width: 98% !important;
}

.initialize-auto-timer-content {
  min-height: 300px;
}

.initialize-failure-list {
  font-family: "Montserrat",sans-serif;
  font-size: small;
  max-height: 240px;
  margin-top: 4px;
  --background: white;
  --overflow-y: scroll;
}

.patient-data-row {
  margin-bottom: 32px;
}

.init-form-patient-name {
  margin-right: 16px;
}

.init-form-patient-data {
  margin-left: 16px;
}

@media (max-width: 1199px) {
  .blood-pressure-row ion-col {
    font-size: 12px;
  }

  #weight {
    font-size: 12px;
  }

  .init-form-title {
    font-size: 24px;
  }

  .init-form-bp-1:after {
    content: "BP 1:";
  }

  .init-form-bp-2:after {
    content: "BP 2:";
  }

  .init-form-bp-3:after {
    content: "BP 3:";
  }
}

@media (min-width: 1200px) {
  .blood-pressure-row ion-col {
    font-size: 16px;
  }

  #weight {
    font-size: 16px;
  }

  .init-form-title {
    font-size: 32px;
  }

  .init-form-bp-1:after {
    content: "Blood Pressure 1:";
  }

  .init-form-bp-2:after {
    content: "Blood Pressure 2:";
  }

  .init-form-bp-3:after {
    content: "Blood Pressure 3:";
  }
}



